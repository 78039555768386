import React from 'react'
import SEO from '../globalComponents/seo'

export default function NotFoundPage() {
  return (
    <>
      <SEO title="404: Not found" />
      <div>404</div>
    </>
  )
}
